export const menuItems = [
  {
    name: 'Главная',
    path: '/',
    icon: 'HomeIcon',
  },
  {
    name: 'Чаты',
    path: '/chat',
    icon: 'ChatIcon',
    notification: true,
  },
  {
    name: 'Казуальные игры',
    icon: 'CasualIcon',
    children: [
      {
        name: 'Пазлы',
        path: '/casual-games',
        icon: 'PuzzleIcon',
        gameName: 'puzzle',
        disableWhenGameProcess: true,
      },
      {
        name: 'Тетрис на раздевание',
        path: '/casual-games',
        icon: 'TetrisIcon',
        gameName: 'tetris',
        link: '/tetris/select',
        disableWhenGameProcess: true,
      },
      {
        name: 'Пятнашки',
        path: '/casual-games',
        icon: 'TagIcon',
        gameName: 'tag',
        disableWhenGameProcess: true,
      },
    ],
  },
  {
    name: 'ЭроКвиз',
    path: '/quiz',
    icon: 'QuizIcon',
  },
  {
    name: 'Статистика',
    path: '/stat',
    icon: 'StatIcon',
  },
  {
    name: 'Трофеи',
    path: '/trophy',
    icon: 'TrophyIcon',
  },
];
