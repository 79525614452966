<template>
  <div class="personal-sidebar">
    <div class="personal-sidebar-header">
      <div class="personal-sidebar-header-title">
        Профиль
      </div>
      <button
        class="personal-toggle js-personal-toggle"
        @click="toggleProfile"
      >
        <span class="icon-close" />
      </button>
    </div>

    <UserInfo />

    <a
      href=""
      class="logout"
      @click.prevent="logout"
    ><span class="icon-logout" />Выйти из аккаунта</a>
    <div class="personal-sidebar-bottom">
      <a v-if="policyUrl" target="_blank" :href="policyUrl">Политика обработки данных</a>
      <a target="_blank" :href="regulationsUrl">Пользовательское соглашение</a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/store';
import { useAuthStore } from '@/store/auth';
import UserInfo from '../UserInfo.vue';

export default {
  name: 'ProfileMenu',
  components: {
    UserInfo,
  },
  emits: ['close'],
  computed: {
    policyUrl() {
      return process.env.VUE_APP_POLICY_URL;
    },
    regulationsUrl() {
      return process.env.VUE_APP_REGULATIONS_URL;
    },
  },
  methods: {
    ...mapActions(useMainStore, ['toggleProfile']),
    ...mapActions(useAuthStore, ['logout']),
  },
};
</script>
