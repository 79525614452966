import { defineStore } from 'pinia';
import Http from '@/Http';

export const useChatStore = defineStore('chats', {
  state: () => ({
    girls: [],
    chats: [],
  }),
  getters: {
    getChatByGirlId: (state) => (id) => state.chats.find((chat) => chat.intelocutor.id === id),
    getGirlById: (state) => (id) => state.girls.find((girl) => girl.id === id),
    // eslint-disable-next-line no-return-assign, no-param-reassign
    getTotalUnreadMessages: (state) => () => state.chats.reduce((accum, item) => accum += item.unreadMessages, 0),
  },
  actions: {
    async getChatList() {
      const result = await Http.get('api/chat/list');
      if (result) {
        const chats = result.data.map((item) => ({
          ...item,
          unreadMessages: item.unread_messages.length || 0,
          imgUrl: item.intelocutor.photos[0].url,
          id: item.chat_id,
        }));
        this.chats = chats;
      }
    },
    async getGirlsList() {
      const result = await Http.get('api/chat/interlocutors');
      if (result && result.data.length) {
        this.girls = result.data;
      }
    },
    resetUnreadMessages(chatId) {
      const chat = this.chats.find((item) => item.id === chatId);
      chat.unreadMessages = 0;
    },
  },
});
