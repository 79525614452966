<template>
  <div class="sidebar-personal">
    <a
      class="sidebar-personal-info"
      @click="toggleProfile"
    >
      <img
        v-if="avatarSrc"
        :src="avatarSrc"
        loading="lazy"
        alt="ava"
      >
      <span v-else class="icon-user" />
      <div class="sidebar-personal-caption">
        <div class="sidebar-personal-name">
          {{ userInfo?.name }}
        </div>
        <div class="sidebar-personal-reg">
          Дата регистрации: {{ registrationDate }}
        </div>
      </div>
    </a>
    <a
      href=""
      class="sidebar-personal-edit"
      @click.prevent="editProfile"
    ><span class="icon-edit" /></a>
  </div>
</template>

<script>
import { useMainStore } from '@/store';
import { useUserStore } from '@/store/user';
import { mapActions, mapState } from 'pinia';

export default {
  name: 'UserInfo',
  computed: {
    ...mapState(useUserStore, ['userInfo']),
    avatarSrc() {
      return this.userInfo.avatar;
    },
    registrationDate() {
      const date = new Date(this.userInfo?.created_at.replace(/-/g, '/'));
      // eslint-disable-next-line no-restricted-globals
      if (date && !isNaN(date.getTime())) {
        return date.toLocaleDateString('ru-RU');
      }
      return '';
    },
  },
  methods: {
    ...mapActions(useMainStore, ['toggleProfile', 'showModal']),
    editProfile() {
      this.showModal('profile');
    },
  },
};
</script>

<style>
.sidebar-personal-info {
  cursor: pointer;
}
</style>
