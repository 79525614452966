import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
  state: () => ({
    showMenu: false,
    showProfile: false,
    modalVisible: false,
    modal: {},
    gameProcess: false,
    currentLevel: 1,
    fullscreen: false,
    isMobile: true,
  }),
  getters: {
  },
  actions: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu && this.modal.type === 'blurred') {
        this.hideModal();
      }
    },
    toggleProfile() {
      this.showProfile = !this.showProfile;
    },
    showModal(type, modal) {
      if (Object.keys(this.modal).length) {
        this.hideModal();
      }
      this.stopGame();
      setTimeout(() => {
        this.modalVisible = true;
        this.modal = {
          type,
          ...modal,
        };
      }, 0);
    },
    hideModal() {
      if (this.modal.type === 'game') {
        this.startGame();
      }
      this.modalVisible = false;
      this.modal = {};
    },
    startGame() {
      this.gameProcess = true;
    },
    stopGame() {
      this.gameProcess = false;
    },
    setCurrentLevel(level) {
      this.currentLevel = level;
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    setDeviceAsDesktop() {
      this.isMobile = false;
    },
  },
});
