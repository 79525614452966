import { defineStore } from 'pinia';
import Http from '@/Http';
import { useUserStore } from './user';
import { useChatStore } from './chats';

const authUrl = process.env.VUE_APP_AUTH_URL;
const tokenKey = process.env.VUE_APP_TOKEN_KEY;

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuth: false,
    token: null,
  }),
  actions: {
    async login() {
      const token = localStorage.getItem(tokenKey);
      if (token) {
        this.isAuth = true;
        this.token = token;
        await this.loadData();
        return true;
      }
      this.logout();
      return false;
    },
    async logout() {
      this.isAuth = false;
      this.token = null;
      localStorage.removeItem(tokenKey);
      window.location.replace(authUrl);
    },
    setToken(token) {
      this.isAuth = true;
      localStorage.setItem(tokenKey, token);
      this.token = token;
      // const result = await Http.post('api/user/subswait');
      // if (result) {
      //   return true;
      // }
      // return false;
    },
    async loadData() {
      const promises = [
        useUserStore().getUserInfo(),
        useUserStore().getStatistics(),
        useUserStore().getTrophy(),
        useChatStore().getChatList(),
        useChatStore().getGirlsList(),
      ];
      await Promise.allSettled(promises);
      return true;
    },
  },
});
