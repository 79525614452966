import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueMask from 'v-mask';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import VueYandexMetrika from 'vue-yandex-metrika';
import App from './App.vue';
import router from './router';
import '@/styles/_normalize.css';
import '@/styles/_base.scss';
import '@/styles/_buttons.scss';
import '@/styles/_layout.scss';
import '@/styles/_forms.scss';
import '@/styles/_popup.scss';
import '@/styles/_sliders.scss';

Vue.component('DefaultLayout', DefaultLayout);
Vue.component('AuthLayout', AuthLayout);

Vue.use(VueMask);

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

Vue.use(pinia);

Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_YANDEX_CLIENT_ID,
  router,
  env: process.env.NODE_ENV,
  debug: true,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
});

Vue.config.productionTip = false;

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount('#app');
