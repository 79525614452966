<script>
export default {
  name: 'ImageControlButtons',
  functional: true,
  props: {
    fullscreen: {
      type: Boolean,
      default: () => false,
    },
    downloadUrl: {
      type: String,
      default: () => '',
    },
    showFullscreenButton: {
      type: Boolean,
      default: () => true,
    },
    showButtonsAbove: {
      type: Boolean,
      default: () => false,
    },
  },
  render(h, { props, listeners }) {
    const fullscreenClass = props.fullscreen ? 'icon-close-fullscreen' : 'icon-fullscreen';
    const style = props.showButtonsAbove ? 'z-index: 3000' : '';
    return (
      <div class="game-award-controls gallery-controls" style={style}>
        { props.showFullscreenButton
        && <button class="ctrl-btn swiper-no-swiping" onClick={listeners['toggle']}>
          <span class={fullscreenClass} />
        </button> }
        <a class="ctrl-btn swiper-no-swiping" download href={props.downloadUrl}>
          <span class="icon-download"/>
        </a>
      </div>
    );
  },
};
</script>
