<template>
  <div
    :class="['media-grid-item', addClass]"
    @click="galleryscreenMode"
  >
    <div :class="['wrapper', { fullscreen: fullscreen }]">
      <div
        v-if="showLabel"
        class="media-type"
      >
        {{ type }}
      </div>
      <img
          v-if="!chatVideo"
          :src="url"
          alt=""
          loading="lazy"
      >
      <video
          v-else
          preload="metadata"
          playsinline
          webkit-playsinline
          :src="`${url}#t=0.001`"
      />
      <span
          v-if="isVideoType"
          class="icon-play video-card-icon"
          @click="chatVideo ? showFullscreenModal(true) : ''"
      />
      <ImageControlButtons
          v-if="!allowGallery && !chatVideo"
          :fullscreen="fullscreen"
          :download-url="photo.url"
          @toggle="showFullscreenModal(false)"
      />
    </div>
  </div>
</template>

<script>
import { useMainStore } from '@/store';
import { mapActions, mapState } from 'pinia';
import ImageControlButtons from './ImageControlButtons.vue';

export default {
  name: 'MediaCard',
  components: { ImageControlButtons },
  props: {
    photo: {
      type: Object,
      default: () => {},
    },
    preview: {
      type: Object,
      default: () => {},
    },
    addClass: {
      type: String,
      default: () => '',
    },
    showLabel: {
      type: Boolean,
      default: () => true,
    },
    index: {
      type: Number,
      default: () => 0,
    },
    allowGallery: {
      type: Boolean,
      default: () => true,
    },
    chatVideo: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fullscreen: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ['isMobile']),
    type() {
      if (this.photo.mime.includes('video')) {
        return 'Видео';
      }
      if (this.photo.mime.includes('gif')) {
        return 'GIF';
      }
      return 'Фото';
    },
    isVideoType() {
      return this.type === 'Видео' || this.type === 'GIF';
    },
    url() {
      // на десктопах грузим полные фотки, чтобы слайдер не тупил при открытии
      if ((this.isMobile || this.isVideoType) && this.preview?.url) {
        return this.preview.url;
      }
      return this.photo.url;
    },
    // костыль, чтобы Сафари показывал первый кадр видео в качестве превью
    showPreviewOrFirstFrame() {
      return `${this.url}#t=0.001`;
    },
  },
  methods: {
    ...mapActions(useMainStore, ['showModal']),
    galleryscreenMode() {
      if (!this.allowGallery) {
        return;
      }
      if (this.isMobile) {
        this.$router.push({ name: 'slider', query: { initialIndex: this.index } });
      } else {
        this.showModal('gallery', { initialIndex: this.index });
      }
    },
    showFullscreenModal(playVideoInstantly) {
      this.showModal('mediaFullscreen', { url: this.photo.url, isVideo: this.isVideoType, playVideoInstant: playVideoInstantly });
    },
  },
};
</script>

<style lang="scss">
.media-grid-item {
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: unset;
  }
}
.video-card-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  z-index: 2;
  color: $color-white;
  left: 50%;
  top: 50%;
}
.icon-play:before {
  position: relative;
  left: 2px;
}
</style>
