<template>
  <footer class="footer">
    <a v-if="policyUrl" target="blank" :href="policyUrl">Политика обработки данных</a>
    <a target="blank" :href="regulationsUrl">Пользовательское соглашение</a>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    policyUrl() {
      return process.env.VUE_APP_POLICY_URL;
    },
    regulationsUrl() {
      return process.env.VUE_APP_REGULATIONS_URL;
    },
  },
};
</script>
